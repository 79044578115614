

import { firestore } from '@/firebase';
import { ROOT_ACTIONS } from '@/store/actions';
import { USER_GETTERS } from '@/store/user/getters';
import { Phase } from '@/types';
import { collection, getDoc, getDocs, orderBy, query, where, doc, addDoc, FieldPath, deleteDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';


@Component
export default class ProgramScreenCastsCard extends Vue {
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) phases!: Phase[];

    screencasts = [] as any[];


    get currentUser() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    open = false;

    async fetchScreenCasts() {
        this.screencasts = [];
        const result = await getDocs(query(collection(firestore, 'content-assignments'), where('user', '==', this.userId)));
        for (const assignment of result.docs) {
            // get doc.data().contentId
            if (!assignment.data().contentId) {
                continue;
            }
            const content = await getDoc(doc(firestore, 'content-items', assignment.data().contentId));
            this.screencasts.push({
                id: assignment.id,
                title: content.data()!.title,
                completed: assignment.data().completed,
            })
        }
    }

    async mounted() {
        await this.fetchScreenCasts();
    }

    @Watch('phases', { immediate: true })
    async onPhasesChange() {
        for (const phase of this.phases) {
            const start = phase.start.toDate();
            const end = phase.end.toDate();
            const now = new Date();
            if (now >= start && now <= end) {
                this.selectedPhase = phase.id!;
                break;
            }
        }
    }

    addContentAssignmentDialog = false;

    content = [] as any[];
    selectedContent = '';

    selectedPhase = '';

    @Watch('addContentAssignmentDialog')
    async onOpenAddContentAssignmentDialog() {
        if (this.addContentAssignmentDialog) {
            const docs = await getDocs(query(collection(firestore, 'content-items')));
            this.content = docs.docs.map(doc => {
                return {
                    id: doc.id,
                    title: doc.data().title,
                    language: doc.data().language,
                }
            });
        }
    }

    async addContentAssignment() {
        if (!this.selectedContent || !this.selectedPhase) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Please select a screencast and a phase',
            })
            return;
        }
        const assignmentDoc = await addDoc(collection(firestore, 'content-assignments'), {
            user: this.userId,
            contentId: this.selectedContent,
            completed: false,
            requestedBy: this.currentUser.id,
            phase: this.selectedPhase,
        });
        
        // add actual activity
        const activitiesinPhase = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', this.selectedPhase)));
        // find an activity where the name contains 'screencast' (lowercase)
        const activity = activitiesinPhase.docs.find(doc => doc.data().name.toLowerCase().includes('screencast'));
        const screencast = this.content.find(content => content.id === this.selectedContent);
        this.addContentAssignmentDialog = false;
        if (activity) {
            const activityDoc = await addDoc(collection(firestore, 'actual-activities'), {
                actualHours: 1,
                comment: `Screencast: ${screencast.title}`,
                activityId: activity.id,
                date: Timestamp.fromDate(new Date()),
                participantConfirmed: false,
                participantPresent: true,
                skipEmail: true,
            });

            await updateDoc(doc(firestore, 'content-assignments', assignmentDoc.id), {
                actualActivityId: activityDoc.id,
            });
            
        } else {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'orange',
                text: 'No screencast activity found in this phase',
            })
        }
        this.$emit('refresh');
    }

    deleteScreencast = async (id: string) => {
        const assignment = await getDoc(doc(firestore, 'content-assignments', id));
        const contentItem = await getDoc(doc(firestore, 'content-items', assignment.data()!.contentId));
        const phase = assignment.data()!.phase;
        if(phase){
            // delete the time registration from the screencast.phase
            // find a activity where the name contains 'screencast' (lowercase)
            const activitiesinPhase = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', phase)));
            const activity = activitiesinPhase.docs.find(doc => doc.data().name.toLowerCase().includes('screencast'));
            if(activity){
                const actualActivities = await getDocs(query(collection(firestore, 'actual-activities'), where('activityId', '==', activity.id)));
                for(const actualActivity of actualActivities.docs){
                    console.log('actual-activity', actualActivity.data());
                //   if the comment contains the screencast title, delete it
                    if(actualActivity.data().comment.includes(contentItem.data()!.title)){
                        await deleteDoc(doc(firestore, 'actual-activities', actualActivity.id));
                    }
                }
            }
        }
        await deleteDoc(doc(firestore, 'content-assignments', id));
        
        this.$emit('refresh');
    }
}


